import queryParams from '@/services/queryParams'
import apiInstance from './index'

export default {
  async getRepresentatives(paramsObj = {}, filtersObj = {}) {
    const query = queryParams.serializeParams(paramsObj, filtersObj)
    return await apiInstance.get(`assign-representatives${query}`)
  },

  async storeRepresentative(data) {
    return await apiInstance.post('assign-representatives', data)
  },

  async getRepresentativeInfo(id) {
    return await apiInstance.get(`assign-representatives/${id}`)
  },

  async updateRepresentative(data) {
    return await apiInstance.put(`assign-representatives/${data.id}`, data)
  },

  async deleteRepresentative(data) {
    return await apiInstance.delete(`assign-representatives/${data.id}`)
  },
}
