<template>
  <b-row>
    <b-col cols="12">
      <h5>
        <strong>{{ $t('clubs.representatives.representativeRegistration') }}</strong>
      </h5>
      <span class="i-text-request-info">
        {{ $t('corporate.create.textRequest') }}
      </span>
      <hr>
    </b-col>
    <b-col md="12">
      <b-overlay
        :show="applyOverlay"
        :class="applyOverlay ? 'p-2 mb-1' : 'mb-1'"
        opacity="1"
      >
        <template #overlay>
          <div class="d-flex align-items-center justify-content-center mt-1">
            <b-spinner
              type="border"
              variant="primary"
            />
          </div>
          <div class="d-flex justify-content-center">
            <p class="pt-1">
              {{ $t('loading') }}
            </p>
          </div>
        </template>
        <b-row>
          <b-col
            v-if="!applyOverlay"
            md="12"
          >
            <validation-observer ref="representativesRules">
              <b-form @submit.prevent="representativesValidation()">
                <b-row>
                  <b-col
                    v-if="!showInstrumentField"
                    md="6"
                  >
                    <b-form-group label-for="representative-other-power">
                      {{ $t('clubs.instrumentNumber.labels.instrumentNumbers') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="instrumentNumbers"
                        rules="required"
                      >
                        <v-select
                          id="representative-other-power"
                          v-model="form.instrumentNumbers"
                          :state="errors.length > 0 ? false : null"
                          multiple
                          label="type"
                          :reduce="name => name.id"
                          :options="instruments"
                          :placeholder="$t('clubs.representatives.placeholder.selectInstrumentNumbers')"
                        >
                          <div slot="no-options">
                            {{ $t('generic.noRecordsFound') }}
                          </div>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-else
                    md="6"
                  >
                    <b-form-group label-for="representative-other-power">
                      {{ $t('clubs.instrumentNumber.labels.instrumentNumber') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="instrumentNumber"
                        rules="required"
                      >
                        <b-input-group>
                          <b-form-input
                            id="society-duration"
                            v-model="form.instrumentNumber"
                            class="form-control"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('clubs.representatives.placeholder.selectInstrumentNumber')"
                            disabled
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <label
                      label-for="representative-undefined"
                      class="i-font-size-date"
                    >
                      {{ $t('clubs.representatives.admitMoreThanOneRepresentative') }} <br>
                      <b-form-checkbox
                        v-model="form.hasManyRepresentatives"
                        checked="true"
                        name="check-button"
                        switch
                        inline
                      >
                        <span>
                          {{ form.hasManyRepresentatives ? $t('generic.yes') : $t('generic.not') }}
                        </span>
                      </b-form-checkbox>
                    </label>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    v-if="form.hasManyRepresentatives"
                    md="6"
                  >
                    <b-form-group label-for="representatives-name">
                      {{ $t('clubs.representatives.representatives') }}
                      <span
                        v-if="!applyOtherRep && userList.length"
                        class="text-danger"
                      >
                        *
                      </span>
                      <validation-provider
                        #default="{ errors }"
                        name="representatives"
                        :rules="applyOtherRep || userList.length == 0 ? '' : 'required'"
                      >
                        <v-select
                          id="representatives-name"
                          v-model="form.representatives"
                          :state="errors.length > 0 ? false : null"
                          multiple
                          label="name"
                          :reduce="name => name.hash"
                          :options="userList"
                          :placeholder="$t('clubs.representatives.placeholder.selectRepresentatives')"
                          :disabled="userList.length == 0"
                        >
                          <div slot="no-options">
                            {{ $t('generic.noRecordsFound') }}
                          </div>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-else
                    md="6"
                  >
                    <b-form-group label-for="representative-name">
                      {{ $t('clubs.representatives.representative') }}
                      <span
                        v-if="!applyOtherRep && userList.length"
                        class="text-danger"
                      >
                        *
                      </span>
                      <validation-provider
                        #default="{ errors }"
                        name="representativeIn"
                        :rules="applyOtherRep || userList.length == 0 ? '' : 'required'"
                      >
                        <v-select
                          id="representative-name"
                          v-model="form.representativeId"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :state="errors.length > 0 ? false : null"
                          label="name"
                          :reduce="name => name.hash"
                          :options="userList"
                          :placeholder="$t('clubs.representatives.placeholder.selectRepresentative')"
                          :disabled="applyOtherRep || userList.length == 0"
                        >
                          <div slot="no-options">
                            {{ $t('generic.noRecordsFound') }}
                          </div>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-button
                      class="mt-2"
                      :variant="applyOtherRep ? 'secondary' : 'primary'"
                      pill
                      :disabled="userList.length == 0"
                      @click="applyOtherRep = !applyOtherRep"
                    >
                      <span v-if="applyOtherRep">{{ $t('buttons.cancel') }}</span>
                      <span v-else>{{ form.hasManyRepresentatives ? $t('clubs.representatives.addNewRepresentatives') : $t('clubs.representatives.addNewRepresentative') }}</span>
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="applyOtherRep"
                    md="6"
                  >
                    <b-form-group
                      v-if="!form.hasManyRepresentatives"
                      label-for="representative-other-option"
                    >
                      {{ $t('clubs.representatives.newRepresentative') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="newRepresentative"
                        rules="required|nameRegex|min:1|max:255"
                      >
                        <b-input-group>
                          <b-form-input
                            id="representative-other-option"
                            v-model="form.otherRepresentative"
                            :state="errors.length > 0 ? false:null"
                            class="form-control"
                            :placeholder="$t('clubs.representatives.placeholder.enterNewRepresentative')"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      v-else
                      label-for="new-representatives-other-option"
                    >
                      {{ $t('clubs.representatives.newRepresentatives') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="newRepresentatives"
                        rules="required|min:1|max:255"
                      >
                        <v-select
                          id="new-representatives-other-option"
                          v-model="form.otherRepresentatives"
                          :state="errors.length > 0 ? false:null"
                          multiple
                          taggable
                          push-tags
                          :placeholder="$t('clubs.representatives.placeholder.enterNewRepresentatives')"
                          label="title"
                        >
                          <div slot="no-options">
                            {{ $t('generic.noRecordsFound') }}
                          </div>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label-for="representative-powers-granted">
                      {{ $t('clubs.representatives.powersGranted') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="powersGranted"
                        rules="required"
                      >
                        <v-select
                          id="representative-powers-granted"
                          v-model="form.powersGranted"
                          :state="errors.length > 0 ? false : null"
                          multiple
                          label="name"
                          :reduce="name => name.id"
                          :options="powers"
                          :placeholder="$t('clubs.representatives.placeholder.selectPowersGranted')"
                        >
                          <div slot="no-options">
                            {{ $t('generic.noRecordsFound') }}
                          </div>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="6"
                    md="4"
                    lg="3"
                  >
                    <b-form-group for="society-incorporation-date">
                      {{ $t('clubs.representatives.validityPeriod') }}
                      <span
                        v-if="!form.undefinedDate"
                        class="text-danger"
                      >
                        *
                      </span>
                      <validation-provider
                        #default="{ errors }"
                        name="validityPeriod"
                        :rules="form.undefinedDate ? '' : 'required'"
                      >
                        <flat-pickr
                          id="representative-date-range"
                          v-model="form.dateRange"
                          class="form-control"
                          :state="errors.length > 0 ? false:null"
                          :config="config"
                          :disabled="form.undefinedDate"
                          :placeholder="$t('clubs.representatives.placeholder.selectADateRange')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="6"
                    md="4"
                    lg="3"
                  >
                    <label
                      label-for="representative-undefined"
                      class="i-font-size-date"
                    >
                      {{ $t('generic.undefinedDate') }} <br>
                      <b-row>
                        <b-col md="4">
                          <b-form-checkbox
                            v-model="form.undefinedDate"
                            checked="true"
                            name="check-button"
                            switch
                            inline
                          >
                            <span>{{ form.undefinedDate ? $t('generic.yes') : $t('generic.not') }}</span>
                          </b-form-checkbox>
                        </b-col>
                      </b-row>
                    </label>
                  </b-col>
                  <b-col
                    v-if="applyTextArea"
                    md="12"
                  >
                    <b-form-group label-for="representative-special">
                      {{ $t('clubs.representatives.specialPowers') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="specialPowers"
                        rules="required|clubNameRegex|min:1|max:255"
                      >
                        <b-form-textarea
                          id="representative-special"
                          v-model="form.specialPowersGranted"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('clubs.representatives.placeholder.enterSpecialPowers')"
                          rows="3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <hr>
                    <b-button
                      v-if="(checkPermission(['ALLOW_ALL']) || checkPermission(['CREATE_ASSIGN_REPRESENTATIVES'])) && actions.name == 'create'"
                      class="float-right ml-1"
                      variant="primary"
                      pill
                      type="submit"
                      :style="colorPrimaryBtn"
                    >
                      {{ $t( 'buttons.save') }}
                    </b-button>
                    <b-button
                      v-if="(checkPermission(['ALLOW_ALL']) || checkPermission(['EDIT_ASSIGN_REPRESENTATIVES'])) && actions.name == 'edit'"
                      class="float-right ml-1"
                      variant="primary"
                      pill
                      type="submit"
                      :style="colorPrimaryBtn"
                    >
                      {{ $t( 'buttons.update') }}
                    </b-button>
                    <b-button
                      v-if="(checkPermission(['ALLOW_ALL']) || checkPermission(['EDIT_ASSIGN_REPRESENTATIVES'])) && actions.name == 'edit'"
                      class="float-right"
                      variant="secondary"
                      pill
                      @click="$emit('change-type-view')"
                    >
                      {{ $t('buttons.cancel') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-col>
          <b-col
            v-else
            cols="12"
            class="i-height-div"
          />
        </b-row>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable no-nested-ternary */
// eslint-disable-next-line import/extensions
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import { powersGranted } from '@/services/catalogsService'
import { mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, min, max, nameRegex, clubNameRegex } from '@validations'
import { BFormGroup, BForm, BFormInput, BFormTextarea, BButton, BFormCheckbox } from 'bootstrap-vue'
import checkPermission from '@/auth/permissions'
import clubService from '@/services/clubsService'
import representativeService from '@/services/representativeService'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BForm,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormCheckbox,
    flatPickr,
    vSelect,
  },

  directives: {
    Ripple,
  },

  props: {
    actions: {
      type: Object,
      default: () => {},
    },
    oldActive: {
      type: Number,
      default: 0,
    },
    stepNumber: {
      type: Number,
      default: null,
    },
    paramsSociety: {
      type: Object,
      default: () => {},
    },
    paramsRepresentative: {
      type: Object,
      default: () => {},
    },
    stepPreviousNumber: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      userList: [],
      instrumentList: [],
      powersGrantedList: [],
      applyOtherRep: true,
      applyTextArea: false,
      applyOverlay: false,
      showInstrumentField: false,
      form: {
        id: null,
        clubId: null,
        hasManyRepresentatives: false,
        representativeId: null,
        representatives: [],
        otherRepresentative: null,
        otherRepresentatives: [],
        powersGranted: [],
        specialPowersGranted: '',
        instrumentNumbers: [],
        instrumentNumber: null,
        undefinedDate: false,
        dateRange: '',
        status: true,
        isCurrent: true,
      },
      config: {
        mode: 'range',
        dateFormat: 'd-m-Y',
        altFormat: 'Y-m-d',
        locale: Spanish,
        // minDate: 'today',
      },
      required, // validation
      min, // validation
      max, // validation
      nameRegex, // validation
      clubNameRegex, // validation
    }
  },

  computed: {
    ...mapGetters({
      clubInfo: 'clubInfo',
      colorPrimaryBtn: 'colorPrimaryBtn',
    }),

    instruments() {
      const arrayR = []
      this.instrumentList.forEach(item => {
        const objR = {
          id: item.hash,
          instrument_number: item.instrument_number,
          type: `${this.$t(item.type)} - ${item.instrument_number}`,
          key: item.key,
        }
        arrayR.push(objR)
      })
      return arrayR
    },

    powers() {
      const list = this.powersGrantedList.map(c => ({ id: c.hash, name: c.labelables[0].label, is_special: c.is_special }))
      return list
    },
  },

  watch: {
    '$i18n.locale': function (newVal) {
      this.assignConfigLocale(newVal)
      this.fetchPowersGranted()
    },

    'form.hasManyRepresentatives': function (itemA) {
      if (itemA) {
        this.form.representativeId = null
        this.form.otherRepresentative = null
      } else {
        this.form.representatives = []
        this.form.otherRepresentatives = []
      }
    },

    'form.instrumentNumbers': function (item) {
      if (item.length > 0) {
        this.filterUsersList(item)
      } else {
        this.userList = []
        this.form.representatives = []
        this.form.representativeId = null
        this.form.otherRepresentative = null
        this.form.otherRepresentatives = []
      }
    },

    'form.powersGranted': function (itemE) {
      let counter = 0

      itemE.forEach(elem => {
        const auxSpecial = this.powers.filter(e => e.id == elem && e.is_special)

        if (auxSpecial.length > 0) {
          counter += 1
        }
      })

      this.applyTextArea = (counter > 0)

      if (!this.applyTextArea) {
        this.form.specialPowersGranted = ''
      }
    },

    'form.undefinedDate': function (pItem) {
      if (pItem) {
        this.form.dateRange = ''
      }
    },

    userList(arrayUsers) {
      if (!this.applyOtherRep) {
        this.applyOtherRep = (arrayUsers.length == 0)
      }
    },

    applyOtherRep(item) {
      if (item && !this.form.hasManyRepresentatives) {
        this.form.representativeId = null
      } else if (!item && !this.form.hasManyRepresentatives) {
        this.form.otherRepresentative = null
      } else if (!item && this.form.hasManyRepresentatives) {
        this.form.otherRepresentative = null
        this.form.otherRepresentatives = null
      }
    },

    stepNumber(pCurrent) {
      if (pCurrent == 3) {
        this.$refs.representativesRules.validate().then(success => {
          if (success) {
            const formRepresentatives = this.assignDataXSteps()
            this.$emit('save-data-step-three', formRepresentatives, true, this.clubInfo.societies.length > 0)
          } else {
            this.$emit('save-data-step-three', null, false, null)
          }
        })
      }
    },

    stepPreviousNumber(sCurrent) {
      if (sCurrent == 2) {
        const formStepsY = this.assignDataXSteps()
        this.$emit('save-data-prev-three', formStepsY)
      }
    },
  },

  mounted() {
    this.assignConfigLocale(this.$i18n.locale)
    this.assignDataFormRep()
  },

  methods: {
    checkPermission,

    assignConfigLocale(pLocale) {
      if (pLocale == 'es') {
        this.config.locale = Spanish
        this.config.dateFormat = 'd-m-Y'
      } else if (pLocale == 'en') {
        this.config.locale = null
        this.config.dateFormat = 'Y-m-d'
      }
    },

    async assignUsersByInstrumentNumber(valueA) {
      const instrumentArray = []

      valueA.forEach(function (val) {
        this.instrumentList.forEach(el => {
          if (el.hash == val && el.users.length > 0) {
            el.users.forEach(obj => {
              // eslint-disable-next-line prefer-const
              let result = instrumentArray.findIndex(e => e.hash === obj.hash)

              if (result == -1) {
                instrumentArray.push(obj)
              }
            })
          }
        })
      }, this)

      const result = await instrumentArray
      return result
    },

    filterUsersList(itm) {
      this.assignUsersByInstrumentNumber(itm).then(list => {
        this.userList = list

        if (this.form.hasManyRepresentatives) {
          const auxRep = JSON.parse(JSON.stringify(this.form.representatives))

          auxRep.forEach((itemB, indexB) => {
            const listPromise = new Promise((resolve, reject) => {
              resolve(list.filter(a => a.hash == itemB))
            })

            listPromise.then(value => {
              if (value.length == 0) {
                this.form.representatives = this.form.representatives.filter(e => e != itemB)
              }
            })
          })
        } else {
          const hashResults = this.userList.filter(e => e.hash == this.form.representativeId)

          if (hashResults.length == 0) {
            this.form.representativeId = null
          }
        }
      })
    },

    fetchPowersGranted() {
      powersGranted().then(({ data }) => {
        this.powersGrantedList = data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    fetchInstrumentNumbers(clubId, applyValidation) {
      clubService.getInstrumentNumbersByClubId(clubId).then(({ data }) => {
        this.instrumentList = data.data

        if (applyValidation) {
          if (this.oldActive == 2 && this.paramsRepresentative != null) {
            this.assignCurrentDataForm(this.paramsRepresentative, false)
          }

          this.showInstrumentField = false
        }
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    assignDataXSteps() {
      const representativesDataX = {
        id: null,
        club_id: this.clubInfo.hash,
        has_many_representatives: this.form.hasManyRepresentatives,
        representative_id: this.form.representativeId,
        representatives: this.form.representatives,
        other_representative: this.form.otherRepresentative,
        other_representatives: this.form.otherRepresentatives,
        powers_granted: this.form.powersGranted,
        special_powers_granted: this.form.specialPowersGranted,
        instrument_numbers: this.form.instrumentNumbers,
        instrument_number: this.form.instrumentNumber,
        undefined_date: this.form.undefinedDate,
        date_range: this.form.dateRange,
        status: this.form.status,
        // is_current: this.form.isCurrent,
      }

      return representativesDataX
    },

    assignDataFormRep() {
      this.fetchPowersGranted()
      if (this.actions.clubId == null) { // solo aplica cuando se utiliza wizard
        this.fetchInstrumentNumbers(this.paramsSociety.club_id, true)
      } else { // solo aplica cuando es flujo normal
        this.fetchInstrumentNumbers(this.actions.clubId, false)
        this.showInstrumentField = false

        if (this.actions.name == 'edit') {
          this.applyOverlay = true
          representativeService.getRepresentativeInfo(this.actions.representativeId).then(({ data }) => {
            this.assignCurrentDataForm(data.data, true)
            this.applyOverlay = false
          }).catch(error => {
            this.applyOverlay = false
            this.responseCatch(error)
          })
        } else {
          this.form = {
            id: null,
            clubId: null,
            hasManyRepresentatives: false,
            representativeId: null,
            representatives: [],
            otherRepresentative: null,
            otherRepresentatives: [],
            powersGranted: [],
            specialPowersGranted: '',
            instrumentNumbers: [],
            instrumentNumber: null,
            undefinedDate: false,
            dateRange: '',
            status: true,
            isCurrent: true,
          }
        }
      }
    },

    assignCurrentDataForm(dataForm, applyEdit) {
      let specialPg = []
      const hasMany = dataForm.has_many_representatives
      this.config.dateFormat = 'Y-m-d'

      if (applyEdit) {
        specialPg = dataForm.power_granteds.filter(pg => pg.pivot.special_powers_granted != null)
      }

      this.form.id = (applyEdit) ? dataForm.hash : dataForm.id
      this.form.clubId = (applyEdit) ? dataForm.club_hash : dataForm.club_id
      this.form.hasManyRepresentatives = hasMany
      this.form.instrumentNumbers = (applyEdit) ? dataForm.instrument_numbers.map(d => d.hash) : dataForm.instrument_numbers
      this.form.powersGranted = (applyEdit) ? dataForm.power_granteds.map(e => e.hash) : dataForm.powers_granted
      this.form.specialPowersGranted = (applyEdit) ? (specialPg.length) ? specialPg[0].pivot.special_powers_granted : '' : dataForm.special_powers_granted
      this.form.instrumentNumber = (applyEdit) ? null : dataForm.instrument_number
      this.form.undefinedDate = dataForm.undefined_date
      this.form.dateRange = (applyEdit) ? (dataForm.undefined_date) ? '' : [dataForm.date_init.slice(0, 10), dataForm.date_end.slice(0, 10)] : dataForm.date_range
      this.form.status = dataForm.status
      // this.form.isCurrent = dataForm.is_current,

      setTimeout(() => {
        this.form.representativeId = (applyEdit) ? (hasMany) ? null : (dataForm.partners.length) ? dataForm.partners[0].hash : null : dataForm.representative_id
        this.form.representatives = (applyEdit) ? (hasMany) ? dataForm.partners.map(a => a.hash) : [] : dataForm.representatives
        this.form.otherRepresentative = (applyEdit) ? (hasMany) ? null : (dataForm.representatives.length) ? dataForm.representatives[0].name : null : dataForm.other_representative
        this.form.otherRepresentatives = (applyEdit) ? (hasMany) ? (dataForm.representatives.length) ? dataForm.representatives.map(ba => ba.name) : [] : [] : dataForm.other_representatives

        this.applyOtherRep = (this.form.otherRepresentative != null || (this.form.otherRepresentatives.length != null && this.form.otherRepresentatives.length > 0))
      }, 200)

      setTimeout(() => { this.assignConfigLocale(this.$i18n.locale) }, 500)
    },

    representativesValidation() {
      this.$refs.representativesRules.validate().then(success => {
        if (success) {
          const currentConfigLocale = JSON.parse(JSON.stringify(this.config.locale))
          const currentConfigDate = JSON.parse(JSON.stringify(this.config.dateFormat))

          this.config.locale = null
          this.config.dateFormat = 'Y-m-d'

          const representativesDataX = {
            id: null,
            club_id: parseInt(this.actions.clubId, 10),
            has_many_representatives: this.form.hasManyRepresentatives,
            representative_id: this.form.representativeId,
            representatives: this.form.representatives,
            other_representative: this.form.otherRepresentative,
            other_representatives: this.form.otherRepresentatives,
            powers_granted: this.form.powersGranted,
            special_powers_granted: this.form.specialPowersGranted,
            instrument_numbers: this.form.instrumentNumbers,
            instrument_number: this.form.instrumentNumber,
            undefined_date: this.form.undefinedDate,
            date_init: this.form.undefinedDate ? null : this.form.dateRange.slice(0, 10),
            date_end: this.form.undefinedDate ? null : this.form.dateRange.length > 10 ? this.form.dateRange.slice(-11) : this.form.dateRange.slice(0, 10),
            status: this.form.status,
            // is_current: this.form.isCurrent,
          }

          if (this.actions.name == 'edit') {
            representativesDataX.id = this.actions.representativeId

            representativeService.updateRepresentative(representativesDataX).then(({ data }) => {
              this.responseSuccessUpdate(data.message)
              this.$emit('change-type-view')
            }).catch(error => {
              this.config.locale = JSON.parse(JSON.stringify(currentConfigLocale))
              this.config.dateFormat = JSON.parse(JSON.stringify(currentConfigDate))
              this.responseCatch(error)
            })
          } else {
            representativeService.storeRepresentative(representativesDataX).then(({ data }) => {
              this.responseSuccessCreate(data.message)
              this.$emit('change-type-view')
            }).catch(error => {
              this.config.locale = JSON.parse(JSON.stringify(currentConfigLocale))
              this.config.dateFormat = JSON.parse(JSON.stringify(currentConfigDate))
              this.responseCatch(error)
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
